import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from "./Button";
import Input from "./Input";
import DeleteButton from "./DeleteButton";

const Checkbox = ({ name, options, value, hasButtonNewItem = false, onChange = () => {}, ...rest }) => {

    const [internalOptions, setInternalOptions] = useState(options);
    const [internalValue, setInternalValue] = useState([]);
    const [isOpenNewItem, setIsOpenNewItem] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (value instanceof Array) {
            setInternalValue(value);
        }
        setInternalOptions(options);
    }, [options, value]); // Добавьте зависимости

    useEffect(() => {
        if (isOpenNewItem && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOpenNewItem]);

    const handleChange = (name, value, checked) => {
        const newValue = checked
            ? [...internalValue, value]
            : internalValue.filter(v => v !== value);

        setInternalValue(newValue);
        onChange(name, value, checked);
    }

    const handleNewItem = () => {
        setIsOpenNewItem(true);
    }

    const handleSaveNewItem = async (name, value) => {
        if (value.trim() !== "") {
            const response = await onChange(name, value, true);

            setInternalOptions([...internalOptions, { name: value, value: value, isDelete: true }]);
        }
        setIsOpenNewItem(false);
    }

    const handleDeleteItem = async (optionToDelete) => {
        const response = await onChange(name, optionToDelete.value, -1);
        const updatedOptionsData = internalOptions.filter(option => option.value !== optionToDelete.value);
        setInternalOptions(updatedOptionsData);

    }

    const AdditionlOptions = () => {
        return <div></div>
    }

    return (
        <div className="checkbox-group flex flex-col gap-1">
            {internalOptions.map((option, index) => (
                <div className={"flex gap-10 items-center"}>
                    <label key={index} className="checkbox-option block">
                        <input
                            type="checkbox"
                            name={name}
                            value={option.value}
                            checked={internalValue.includes(String(option.value))}
                            onChange={(e) => handleChange(name, option.value, e.target.checked)}
                            className="mr-2"
                            {...rest}
                        />
                        {option.name}
                    </label>
                    {option.isDelete &&
                        <DeleteButton textButton={"Удалить"} itemId={option} onDelete={handleDeleteItem}/>

                    }
                </div>
            ))}
            { isOpenNewItem &&
                <div className="mt-2 pt-4 flex gap-2 border-t">
                    <Input ref={inputRef} name={name} value={""} placeholder={"Введите значение"} size={"small"} onChange={handleSaveNewItem} width={"w-96"} />
                    <Button label={"Добавить"} color={"lite"} size={"small"} />
                </div>
            }
            { hasButtonNewItem && !isOpenNewItem &&
                <div className="mt-2 pt-4  border-t">
                    <Button label={"Добавить"} color={"lite"} size={"small"} onClick={handleNewItem} />
                </div>
            }
        </div>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
};

export default Checkbox;