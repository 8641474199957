import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dateProvider from "../../../provider/DateProvider";

const Input = React.forwardRef(({ name, value, type = 'text', size = 'medium', borderColor = 'gray', width = 'w-full', close = true, onChange = () => {}, placeholder, ...rest }, ref) => {
    const [intermediateValue, setIntermediateValue] = useState(value);

    useEffect(() => {
        setIntermediateValue(value);
    }, [value]);

    const sizeClass = {
        small: 'p-1 text-sm',
        medium: 'p-2 text-base',
        large: 'p-3 text-lg'
    };

    const sizeClassClear = {
        small: 'h-3 w-3',
        medium: 'h-6 w-6',
        large: 'h-6 w-6'
    };

    const borderClass = {
        gray: 'border-gray-300',
        blue: 'border-blue-500',
        red: 'border-red-500'
    };

    const handleChange = (e) => {

        setIntermediateValue(e.target.value);
    };

    const handleBlur = (e) => {
        if ( type === "date") {
            const date = dateProvider.dateToTimestamp(e.target.value);
            onChange(name, date);
        } else {
            onChange(name, e.target.value);
        }
    };

    const onClear = () => {
        setIntermediateValue("");
        onChange(name, "");
    };

    return (
        <div className={"relative " + (type === "date" ? "w-40" : width)}>
            <input
                ref={ref}
                type={type}
                name={name}
                value={intermediateValue}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`w-full ${sizeClass[size]} border ${borderClass[borderColor]} rounded-sm focus:outline-none focus:border-green-500`}
                placeholder={placeholder}
                {...rest}
            />
            {intermediateValue && close &&
                <div className={`absolute top-2 right-2 ${sizeClassClear[size]} cursor-pointer  flex items-center justify-center`} onClick={onClear}>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.57808 4.00363L7.50776 0.51144C7.55687 0.453404 7.51558 0.365234 7.43968 0.365234H6.54906C6.4966 0.365234 6.44638 0.388672 6.41178 0.42885L3.99549 3.30943L1.57919 0.42885C1.54571 0.388672 1.49549 0.365234 1.44191 0.365234H0.551289C0.475396 0.365234 0.434102 0.453404 0.483209 0.51144L3.4129 4.00363L0.483209 7.49582C0.472208 7.50875 0.465151 7.52457 0.462875 7.5414C0.460598 7.55823 0.463198 7.57535 0.470367 7.59075C0.477535 7.60614 0.48897 7.61916 0.503314 7.62824C0.517658 7.63733 0.534309 7.64211 0.551289 7.64202H1.44191C1.49437 7.64202 1.54459 7.61858 1.57919 7.5784L3.99549 4.69782L6.41178 7.5784C6.44526 7.61858 6.49549 7.64202 6.54906 7.64202H7.43968C7.51558 7.64202 7.55687 7.55385 7.50776 7.49582L4.57808 4.00363Z"
                            fill="black" fillOpacity="0.45"/>
                    </svg>
                </div>
            }
        </div>
    );
});

Input.propTypes = {
    type: PropTypes.oneOf(['text', 'password', 'email','date', 'number']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    borderColor: PropTypes.oneOf(['gray', 'blue', 'red']),
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};

export default Input;