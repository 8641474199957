import apiService from "./ApiService";
import Analys from "../models/Analys";
import Radiography from "../models/Radiography";
import Fractures from "../models/Fractures";
import TakeMedicine from "../models/TakeMedicine";

class MedicineProvider {

    async getTakeMedicine(iin,medicineType, takemedicineId) {
        const data = await apiService.post('/takemedicine_read', { iin: iin, medicines_type: medicineType, takemedicine: takemedicineId });
        if (data && data.takemedicines) {
            return data.takemedicines;
        }
        return null;
    }

    async getTakeMedicineByType(iin, medicineType ) {
        const data = await apiService.post('/takemedicine_read', { iin: iin, medicines_type: medicineType, takemedicine: "" });
        if (data && data.takemedicines) {
            return data.takemedicines;
        }
        return null;
    }
    async createTakeMedicine(iin, visitId, medicineType) {
        const data = await apiService.post('/takemedicine_create', { iin: iin, visit_id: visitId, medicines_type: medicineType });
        if (data) {
            return data;
        }
        return null;
    }


}


const medicineProvider = new MedicineProvider();
export default medicineProvider;