import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";

const SymptomsTreatmentForm = ({visit}) => {

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];

    return <FieldSetVisitForm title={"ОЦЕНКА СИМПТОМОВ ЗАБОЛЕВАНИЯ"} id={"base"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="complaint" typeFieldForm={"checkbox"} label={"Жалоба"} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="morning_stiffness" typeFieldForm={"boolean"} label={"Утренняя скованность"} options={booleanOptions} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldNameHours={"duration_of_stiffness_h"} fieldNameMinutes={"duration_of_stiffness_m"}  typeFieldForm={"timer"} label={"Продолжительность скованности"} width={"w-30"} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="patient_assessment_of_stiffness" typeFieldForm={"range"} label={"* Оценка скованности пациентом"} startLabel={"Скованности нет"} endLabel={"Скованность максимально выражена"} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="patient_overall_assessment_of_disease_activity" typeFieldForm={"range"} label={"Общая оценка активности белезни пациентом"} startLabel={"Активности артрита нет, я\n" +
            "чувствую себя почти здоровым"} endLabel={"Артрит чрезвычайно активный, я почти не могу его переносить"} />
    </FieldSetVisitForm>
}

export default SymptomsTreatmentForm;