import React, {useEffect, useState} from "react";
import analysProvider from "../../../../../provider/AnalysProvider";
import {useListVisitsData} from "../../../../../contexts/ListVisitsContext";
import AnalysResultItemTable from "./AnalysResultItemTable";
import Button from "../../../../Part/Form/Button";
import fieldChangeProvider from "../../../../../provider/FieldChangeProvider";
import DeleteButton from "../../../../Part/Form/DeleteButton";
import dateProvider from "../../../../../provider/DateProvider";
import patientProvider from "../../../../../provider/PatientProvider";



const AnalysisTableItem = ({visit, analyseKey, onAddAnalyse,  commonbloodTest, biochemicalTest, onDeattachAnalyseInVisit}) => {
    const [analyse, setAnalyse] = useState(null);
    const { listVisit, setListVisit } = useListVisitsData();
    const [isExist, setIsExist ] = useState(false);


    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(  () =>  {
        const fetchData = async () => {
            const analyseData = await analysProvider.getAnalys(analyseKey);
            setAnalyse(analyseData);

            if (analyseData ) {
                setIsExist(analyseData.getValue("visit_id") === visit.getId());
            }
        }

        fetchData();

    }, [analyseKey, isExist]);


    const handleAttachAnalyseInVisit = async (analyse_id ) => {
        await onAddAnalyse(analyse_id);
        setIsExist(true);
    }

    const handleDeAttachAnalyseInVisit = async (analyse_id ) => {
        await onDeattachAnalyseInVisit(analyse_id);
        setIsExist(false);
    }

    if (analyse) {

        const title = analyse.isCommonBlood() ? "Общий анализ крови" : "Биохимический анализ крови";
        const indicatorsTest = analyse.isCommonBlood() ? commonbloodTest : biochemicalTest;
        const typeAnalyses = analyse.isCommonBlood() ? "commonblood_test_id" : "biochemical_test_id";

        const visit_id = analyse.getValue("visit_id");

        const foundItem = listVisit.find(item => item.getId() === visit_id);

            const results = analyse.getValue("results");

            return <div className={"border-b pb-2 mt-2 first:mt-0  last:border-b-0 last:pb-0 "} >
                <div className={"flex gap-2 items-center  "}>
                    <div className={"w-32 cursor-pointer"}  onClick={handleIsOpen}>
                        { dateProvider.timestampToDate(analyse.getValue("appear_time"))}
                    </div>
                    <div className={"w-72 cursor-pointer underline flex gap-2 items-center"}  onClick={handleIsOpen}>
                        <span>{title}</span>
                        <svg className={"drop-down__svg  " + (isOpen ? " " : "drop-down__svg_close")} width="14" height="9" viewBox="0 0 14 9" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7.08057L7 1.08057L13 7.08057" stroke="#797979" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                        </svg>

                    </div>
                    <div className={"w-48"}>
                        { foundItem &&
                            <span> Прием №{foundItem.getNumber()}</span>
                        }
                    </div>
                    <div className={"w-30"}>

                        {!isExist && !foundItem &&
                            <div className={" "}>
                                <Button label={"Прикрепить"} size={"small"} color={"lite"}
                                        onClick={(e) => {
                                            handleAttachAnalyseInVisit(analyse.getId())
                                        }}/>
                            </div>
                        }

                        {isExist &&
                            <div className={" "}>
                                <DeleteButton
                                    itemId={analyse.getId()}
                                    onDelete={handleDeAttachAnalyseInVisit}
                                    textButton={"Отвязать"}
                                    message={"Вы действительно хотите отвязать анализ от приема?"}/>
                            </div>
                        }
                    </div>

                </div>
                {isOpen &&
                    <div className={""}>
                        <div className={"analys-table border-y mt-6 mb-4 "}>
                            {
                                indicatorsTest && indicatorsTest.map((analysResultItem, index) => {
                                    let value = "";
                                    if (results && results.length) {
                                        const item = results.find(item => item[typeAnalyses] == analysResultItem.value);
                                        if (item) {
                                            value = item.value;
                                        }
                                    }

                                    return <AnalysResultItemTable  key={index} analysResultItem={analysResultItem} analys={analyse} value={value}  isDisabled={true}/>
                                })
                            }
                        </div>
                    </div>
                }
            </div>
    }

    return <div></div>;
}

const AnalysisFullListPatient = ({visit, onAddAnalyse, listAnalysisPatient, commonbloodTest, biochemicalTest, handleDeattachAnalyseInVisit}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [analysisList, setAnalysisList] = useState(false);

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(  () =>  {
        const fetchData = async () => {
            setAnalysisList(listAnalysisPatient);
        }

        fetchData();

    }, [listAnalysisPatient]);



    if (analysisList && analysisList.length > 0) {
        return <div className={""}>
            <div className={"flex gap-5 items-center cursor-pointer"} onClick={handleIsOpen}>
                <span>Всего анализов {analysisList.length} </span>
                <svg className={"drop-down__svg " + (isOpen ? " " : "drop-down__svg_close")} width="14" height="9" viewBox="0 0 14 9" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 7.08057L7 1.08057L13 7.08057" stroke="#797979" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </div>
            {isOpen &&
                <div className={"border bg-white p-5 mt-2 mb-5"}>
                    {analysisList && analysisList.map((analyseKey, index) => {
                        return <AnalysisTableItem analyseKey={analyseKey}
                                                  visit={visit}
                                                  onAddAnalyse={onAddAnalyse}
                                                  commonbloodTest={commonbloodTest}
                                                  biochemicalTest={biochemicalTest}
                                                  onDeattachAnalyseInVisit={handleDeattachAnalyseInVisit}/>
                    })}
                </div>
            }
        </div>;
    }

    return <div/>

}

export default AnalysisFullListPatient;