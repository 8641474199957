import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import IMTFieldVisitForm from "./BaseIdentificationForm/IMTFieldVisitForm";
import {useState} from "react";

const BaseIdentificationForm = ({visit}) => {
    const [height, setHeight] = useState(Number(visit.getPatient().getValue("height")));
    const [weight, setWeight] = useState(Number(visit.getPatient().getValue("weight")));


    const handleHeightChange = (value) => {
        setHeight(value);
    };

    const handleWeightChange = (value) => {
        setWeight(value);
    };

    return <FieldSetVisitForm title={"Основное"} id={"base"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="gender" typeFieldForm={"radio"} label={"Пол"} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="race" typeFieldForm={"radio"} label={"Раса"} />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="height" typeFieldForm={"range"} label={"Рост"} min={"100"} max={"230"} units={"см."} onChange={handleHeightChange}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="weight" typeFieldForm={"range"} label={"Вес"} min={"30"} max={"250"} units={"кг."} step={"0.1"} onChange={handleWeightChange}/>
        <IMTFieldVisitForm height={height} weight={weight}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="telephone" typeFieldForm={"text"} label={"Контактный номер телефона"}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="home_address" typeFieldForm={"text"} label={"Домашний адрес"}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="doctor_fio" typeFieldForm={"text"} label={"Лечащий врач"}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="medical_organization_of_attachment" typeFieldForm={"text"} label={"Медицинская организация прикрепления"}/>

    </FieldSetVisitForm>
}

export default BaseIdentificationForm;