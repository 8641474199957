import Button from "../../../../../Part/Form/Button";
import React, {useEffect, useState} from "react";
import EditFieldVisitForm from "../../Part/EditFieldVisitForm";
import fracturesProvider from "../../../../../../provider/FracturesProvider";
import fieldChangeProvider from "../../../../../../provider/FieldChangeProvider";
import DeleteButton from "../../../../../Part/Form/DeleteButton";


const FieldSetFracturesForm = ({title, children}) => {
    return <div className={""}>
        <div className={"text-base"}>{title}</div>
        {children}
    </div>;
}


const FracturesVisitForm = ({visit, fracturesKey, deleteFractures}) => {

    const [fractures, setFractures] = useState(null);

    useEffect( () => {

        const fetchFractures = async (fracturesKey) => {
            const fracturesData = await fracturesProvider.getFractures(fracturesKey);
            setFractures(fracturesData);
        }

        fetchFractures(fracturesKey) ;

    }, [fracturesKey]);


    const yearOptions = [{name: "-", value: ""}];
    const year = new Date().getFullYear();
    for(let i = year; i > 1900; i-- ) {
        yearOptions.push({name: i, value: i});
    }

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];

    if (fractures) {
        return  <div className={"relative border bg-white p-5 flex flex-col  gap-5 "}>
            <div className={"absolute right-5 top-5"} >
                <DeleteButton
                    itemId={fractures.getId()}
                    onDelete={deleteFractures}/>
            </div>
            <FieldSetFracturesForm title={"Название"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"text"} objectType={"fractures"} fieldObject={"fractures_text"} fractures={fractures} placeholder={"Напишите название"}/>
            </FieldSetFracturesForm>
            <div className={"flex gap-2 items-center"}>
                <span className={"text-base"}>Год установления</span>
                <EditFieldVisitForm visit={visit} typeFieldForm={"select"} objectType={"fractures"} fieldObject={"fractures_year"} fractures={fractures} size={"small"} options={yearOptions} />
            </div>
            <div className={"flex gap-2 items-center flex-wrap"}>
                <span className={"text-base"}>Патологический перелом</span>
                <EditFieldVisitForm visit={visit} typeFieldForm={"boolean"} objectType={"fractures"} fieldObject={"is_fractures"} fractures={fractures} size={"small"} options={booleanOptions} width={"w-20"}/>
            </div>
        </div>
    }

    return  <div></div>;
}

const FracturesTreatmentVisitForm = ({visit}) => {

    const [listFractures, setListFractures ] = useState(visit.getPatient().getValue("fractures"));

    useEffect(() => {
        setListFractures(visit.getPatient().getValue("fractures"));
    },[visit]);

    const handleAddFractures = async () => {
        const data = await fracturesProvider.createFractures(visit.getPatient().getIIN(), visit.getId());
        if (data) {
            setListFractures([...listFractures, data.fractures_id]);
        }
    }

    const handleDeleteFractures = async (fracturesKey) => {
        await fieldChangeProvider.setFieldFractures(fracturesKey, "is_del", true , 1);
        setListFractures((prevListFractures) => {
            return prevListFractures.filter(item => item !== fracturesKey);
        });
    }

    return <div className={"flex gap-5 items-start"}>
            <div className={"w-60 text-sm"}>Переломы</div>
            <div className={"radiography-list flex flex-col grow max-w-xl gap-3"}>
                {
                    listFractures.map((fracturesItemKey, index) => {
                        return <FracturesVisitForm fracturesKey={fracturesItemKey} visit={visit} deleteFractures={handleDeleteFractures}/>
                    })
                }
                <div className={"mt-2"}>
                    <Button color={"lite"} size={"small"} label={"Добавить"} onClick={handleAddFractures}/>
                </div>
            </div>
        </div>;

}

export default FracturesTreatmentVisitForm;