import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import {useState} from "react";
import FracturesTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/FracturesTreatmentVisitForm";

const SoputstvuyshieTreatmentForm = ({visit}) => {
    const [isAnemia, setIsAnemia] = useState(visit.getValue("is_anemia"));
    const [isOncology, setIsOncology] = useState(visit.getValue("is_oncology"));
    const [isChd, setIsChd] = useState(visit.getValue("is_chd"));
    const [isDiabetesMellitus, setIsDiabetesMellitus] = useState(visit.getValue("is_diabetes_mellitus"));
    const [isTuberculosis, setIsTuberculosis] = useState(visit.getValue("is_tuberculosis"));
    const [isAci, setIsAci] = useState(visit.getValue("is_aci"));

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];

    const yearOptions = [{name: "-", value: ""}];
    const year = new Date().getFullYear();
    for(let i = year; i > 1900; i-- ) {
        yearOptions.push({name: i, value: i});
    }

    const handleIsAnemiaChange = (value) => {
        setIsAnemia(value);
    };
    const handleIsOncologyChange = (value) => {
        setIsOncology(value);
    };
    const handleIsChdChange = (value) => {
        setIsChd(value);
    };
    const handleIsDiabetesMellitusChange = (value) => {
        setIsDiabetesMellitus(value);
    };
    const handleIsTuberculosisChange = (value) => {
        setIsTuberculosis(value);
    };
    const handleIsAciChange = (value) => {
        setIsAci(value);
    };

    return <FieldSetVisitForm title={"Сопутствующее состояние/заболевания"} id={"base"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="is_anemia" typeFieldForm={"boolean"} label={"Анемия"} options={booleanOptions}
                            onChange={handleIsAnemiaChange}/>
        {isAnemia === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="anemia_year" typeFieldForm={"select"}
                                label={"Год установления"} options={yearOptions} width={"w-40"}/>
        }
        {isAnemia === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject={"anemia_type"} typeFieldForm={"select"}
                                label={"Вид"}  />
        }
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="is_oncology" typeFieldForm={"boolean"} label={"Онкология"} options={booleanOptions}
                            onChange={handleIsOncologyChange}/>
        {isOncology === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="oncology_year" typeFieldForm={"select"}
                                label={"Год установления"} options={yearOptions} width={"w-40"}/>
        }
        {isOncology === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="oncology_localization" typeFieldForm={"text"}
                                label={"Локализация онкологии"}  width={"w-80"}  />
        }


        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="is_chd" typeFieldForm={"boolean"} label={"ИБС"} options={booleanOptions}
                            onChange={handleIsChdChange}/>
        {isChd === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="chd_year" typeFieldForm={"select"}
                                label={"Год установления"} options={yearOptions} width={"w-40"}/>
        }
        {isChd === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="chd_type" typeFieldForm={"select"}
                                label={"Вид ИБС"}    />
        }



        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="is_diabetes_mellitus" typeFieldForm={"boolean"} label={"Сахарный диабет"} options={booleanOptions}
                            onChange={handleIsDiabetesMellitusChange}/>

        {isDiabetesMellitus === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="diabetes_mellitus_year" typeFieldForm={"select"}
                                label={"Год установления"} options={yearOptions} width={"w-40"}/>
        }

        {isDiabetesMellitus === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="diabetes_mellitus_type" typeFieldForm={"select"}
                                label={"Вид диабета"}    />
        }

        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="is_aci" typeFieldForm={"boolean"} label={"ОНМК"} options={booleanOptions}
                            onChange={handleIsAciChange}/>

        {isAci === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="aci_year" typeFieldForm={"select"}
                                label={"Год установления"} options={yearOptions} width={"w-40"}/>
        }


        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="is_tuberculosis" typeFieldForm={"boolean"} label={"Туберкулез"} options={booleanOptions}
                            onChange={handleIsTuberculosisChange}/>

        {isTuberculosis === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="tuberculosis_year" typeFieldForm={"select"}
                                label={"Год установления"} options={yearOptions} width={"w-40"}/>
        }

        {isTuberculosis === "1" &&
            <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="tuberculosis_type" typeFieldForm={"select"}
                                label={"Вид TBC"}    />
        }

        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="related_conditions" typeFieldForm={"checkbox"} label={"Другие сопутствующие состояния/заболевания"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="complications" typeFieldForm={"checkbox"} label={"Осложнения"} hasButtonNewItem={true}/>
        <FracturesTreatmentVisitForm visit={visit}/>

        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="assessment_of_the_activity_by_the_doctor" typeFieldForm={"range"} label={"Общая оценка активности белезни врачом"} startLabel={"Активности нет"} endLabel={"Высокая активность"} />

    </FieldSetVisitForm>
}

export default SoputstvuyshieTreatmentForm;