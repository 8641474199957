import React, {useEffect, useState} from "react";
import radiographyProvider from "../../../../../provider/RadiographyProvider";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import imagesProvider from "../../../../../provider/ImagesProvider";
import uploadFileProvider from "../../../../../provider/UploadFileProvider";
import DeleteButton from "../../../../Part/Form/DeleteButton";
import fieldChangeProvider from "../../../../../provider/FieldChangeProvider";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Fancybox } from '@fancyapps/ui';

const FieldSetRadiographyForm = ({title, children}) => {
    return <div className={""}>
        <div className={"text-base"}>{title}</div>
        {children}
    </div>;
}

const RadiographyForm = ({radiography, visit, pictures, onFentch, onDelete}) => {

    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {});
    }, []);

    const handleUploadFile = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('idd', radiography.getId());
        formData.append('type', 'radiography');

        try {
            const response = await uploadFileProvider.uploadFile(formData);
            console.log('Файл успешно загружен', response);
            // Обновите изображения или выполните другую логику после загрузки
            //setPictures([...pictures, response.uploadedPicture]);
            await onFentch();
        } catch (error) {
            console.error('Ошибка при загрузке файла', error);
        }
    };

    const handleDeleteRadiography = async (radiography_id) => {

        const data = await fieldChangeProvider.setFieldRadiography(radiography_id, "is_del", true);
        if (data && data.ok) {
            onDelete(radiography_id);
        }

    }

    if (radiography) {
        return  <div className={"flex flex-col  gap-5 relative"}>

            <FieldSetRadiographyForm title={"Название"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"textarea"} objectType={"radiography"} fieldObject={"name"} radiography={radiography} placeholder={"Напишите название"}/>
            </FieldSetRadiographyForm>
            <div className={" "}>
                <div className={"text-base"}>Снимки</div>
                <div className={"grid grid-cols-5 gap-2"}>
                    {
                        pictures && pictures.map((picture) => {
                            const url = imagesProvider.getImageUrl(picture);
                            return <a href={url} className={"w-24 h-24 border"} data-fancybox="gallery"   >
                                <img className={"w-full"} src={imagesProvider.getImageUrlShort(picture)}/>
                            </a>
                        })
                    }
                    <div className={"w-24 h-24 flex items-center justify-center border relative cursor-pointer"}>
                        <span className={"text-sm text-center"}>Добавить фото</span>
                        <input type={"file"} onChange={handleUploadFile} className={"absolute opacity-0 w-full h-full cursor-pointer"}/>
                    </div>

                </div>

            </div>
            <div className={"flex gap-2 items-center"}>
                <span className={"text-base"}>Дата от</span>
                <EditFieldVisitForm visit={visit} typeFieldForm={"date"} objectType={"radiography"} fieldObject={"appear_time"} radiography={radiography} size={"small"} />
            </div>
            <FieldSetRadiographyForm title={"Заключение"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"textarea"} objectType={"radiography"} fieldObject={"conclusion"} radiography={radiography} placeholder={"Напишите заключение"}/>
            </FieldSetRadiographyForm>
            <FieldSetRadiographyForm title={"Описание"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"textarea"} objectType={"radiography"} fieldObject={"description"} radiography={radiography} placeholder={"Напишите описание"}/>
            </FieldSetRadiographyForm>
        </div>;
    }
    return <div></div>;

}

const RadiographyInfo = ({radiography, visit}) => {
    return <div className={"flex flex-col  gap-5 "}>
            <div className={"text-base"}>Название</div>
            <div className={"uppercase text-lg leading-none font-bold"}>{radiography.getValue("name")}</div>
            <div className={""}>Дата от <strong>{radiography.getAppearTime()}</strong></div>
        </div>
}

const RadiographyVisitForm = ({radiographyKey, visit, isOpenForm = false, onDelete}) => {
    const [isOpen, setIsOpen] = useState(isOpenForm);

    const [radiography, setRadiography] = useState(null);
    const [pictures, setPictures] = useState([]);

    const fetchRadiography = async () => {
        const radiography = await radiographyProvider.getRadiography(radiographyKey);
        setRadiography(radiography);
        setPictures(await radiography.getValue("pictures"));
    }

    useEffect(() => {

        fetchRadiography() ;

    }, [radiographyKey]);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    if (radiography) {

        return <div className={"border bg-white p-5 "}>
            {
                isOpen
                    ?
                <RadiographyForm radiography={radiography} visit={visit} pictures={pictures} onFentch={fetchRadiography} onDelete={onDelete}/>
                    :
                <RadiographyInfo radiography={radiography} visit={visit}/>
            }
            <hr className={"mt-2 mb-2"}/>
            {
                isOpen
                    ?
                    <div className={"flex gap-2 items-center cursor-pointer"} onClick={handleOpen}>
                        Свернуть
                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7.08057L7 1.08057L13 7.08057" stroke="#797979" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    :
                    <div className={"flex gap-2 items-center cursor-pointer"}  onClick={handleOpen}>
                        Развернуть
                        <svg className={"rotate-180"} width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7.08057L7 1.08057L13 7.08057" stroke="#797979" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
            }
        </div>;
    }

    return <div></div>;
}

export default RadiographyVisitForm;