// src/App.js
import React, { useState, useEffect } from 'react';
import Login from './components/Auth/Login';
import Cabinet from './components/Layouts/Cabinet';
import { Fancybox } from '@fancyapps/ui';


const App = () => {
  const [key, setKey] = useState(null);
  const [userId, setUserId] = useState(null);


    useEffect(() => {
        // Check for token in localStorage on initial load
        const keyStorage = localStorage.getItem('key');
        const userIdStorage = localStorage.getItem('user_id');
        if (keyStorage) {
            setKey(keyStorage);
        }
        if (userIdStorage) {
            setUserId(userIdStorage);
        }

        Fancybox.bind("[data-modal]", {
            hideScrollbar: false,
            loop: false,
            parentEl: document.getElementById("root")
        });

    }, []);


  return (
          <div className={"box-container box-border "}>
            {!key || !userId ? (
                <Login setUserId={setUserId} setKey={setKey}/>
            ) : (
                <Cabinet/>
            )}
          </div>
  );
};

export default App;