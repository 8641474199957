
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import dateProvider from "../../../../provider/DateProvider";

const PatientItem = ({patient}) => {

    const formattedDate = dateProvider.strToDate(patient.birthday_time);

    return <div className={"patient-item flex flex-col px-8 py-5 gap-4"}>
        <Link to={"/patient/profile/"+patient.iin} className={"text-2xl flex items-center group green-hover uppercase font-medium "}>
            <span className={"leading-10 block group-hover:color-green-pa   group-hover:underline"}>{patient.fio}</span>
            <svg className={"pointer-events-auto opacity-0 group-hover:opacity-100  "} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6665 10L26.6665 20L16.6665 30" stroke="#5CBB7A" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </Link>
        <div className={"flex gap-5"}>
            <label className={"w-60 text-sm"}>* ИИН пациента</label>
            <div className={"text-base"}>{patient.iin}</div>
        </div>
        <div className={"flex gap-5"}>
            <label className={"w-60 text-sm"}>* Дата рождения</label>
            <div className={"text-base"}>{formattedDate}</div>
        </div>
        <p className={"uppercase text-xl max-w-sm font-bold leading-7"}>

        </p>
    </div>
}

export default PatientItem;